export enum Privileges {

  NO_ACCESS = 'NO_ACCESS', // for internal use: always block access
  //
  REPLY_TEMPLATE = 'REPLY_TEMPLATE',
  REPLY_TEMPLATE__CREATE = 'REPLY_TEMPLATE-CREATE',
  REPLY_TEMPLATE__READ = 'REPLY_TEMPLATE-READ',
  REPLY_TEMPLATE__ADMIN = 'REPLY_TEMPLATE-ADMIN',
  REPLY_TEMPLATE__LISTVIEW = 'REPLY_TEMPLATE-LISTVIEW',
  REPLY_TEMPLATE__UPDATE = 'REPLY_TEMPLATE-UPDATE',
  REPLY_TEMPLATE__DELETE = 'REPLY_TEMPLATE-DELETE',
  IMPORT_SET = 'IMPORT_SET',
  IMPORT_SET__CREATE = 'IMPORT_SET-CREATE',
  IMPORT_SET__READ = 'IMPORT_SET-READ',
  IMPORT_SET__ADMIN = 'IMPORT_SET-ADMIN',
  IMPORT_SET__LISTVIEW = 'IMPORT_SET-LISTVIEW',
  IMPORT_SET__UPDATE = 'IMPORT_SET-UPDATE',
  IMPORT_SET__DELETE = 'IMPORT_SET-DELETE',
  CATEGORIZATION = 'CATEGORIZATION',
  CATEGORIZATION__CREATE = 'CATEGORIZATION-CREATE',
  CATEGORIZATION__READ = 'CATEGORIZATION-READ',
  CATEGORIZATION__ADMIN = 'CATEGORIZATION-ADMIN',
  CATEGORIZATION__LISTVIEW = 'CATEGORIZATION-LISTVIEW',
  CATEGORIZATION__UPDATE = 'CATEGORIZATION-UPDATE',
  CATEGORIZATION__DELETE = 'CATEGORIZATION-DELETE',
  PERSON = 'PERSON',
  PERSON__CREATE = 'PERSON-CREATE',
  PERSON__READ = 'PERSON-READ',
  PERSON__ADMIN = 'PERSON-ADMIN',
  PERSON__LISTVIEW = 'PERSON-LISTVIEW',
  PERSON__UPDATE = 'PERSON-UPDATE',
  PERSON__DELETE = 'PERSON-DELETE',
  CLIENT = 'CLIENT',
  CLIENT__CREATE = 'CLIENT-CREATE',
  CLIENT__READ = 'CLIENT-READ',
  CLIENT__ADMIN = 'CLIENT-ADMIN',
  CLIENT__LISTVIEW = 'CLIENT-LISTVIEW',
  CLIENT__UPDATE = 'CLIENT-UPDATE',
  CLIENT__DELETE = 'CLIENT-DELETE',
  CAMPAIGN_REPORT = 'CAMPAIGN_REPORT',
  CAMPAIGN_REPORT__CREATE = 'CAMPAIGN_REPORT-CREATE',
  CAMPAIGN_REPORT__READ = 'CAMPAIGN_REPORT-READ',
  CAMPAIGN_REPORT__ADMIN = 'CAMPAIGN_REPORT-ADMIN',
  CAMPAIGN_REPORT__LISTVIEW = 'CAMPAIGN_REPORT-LISTVIEW',
  CAMPAIGN_REPORT__UPDATE = 'CAMPAIGN_REPORT-UPDATE',
  CAMPAIGN_REPORT__DELETE = 'CAMPAIGN_REPORT-DELETE',
  CAMPAIGN = 'CAMPAIGN',
  CAMPAIGN__CREATE = 'CAMPAIGN-CREATE',
  CAMPAIGN__READ = 'CAMPAIGN-READ',
  CAMPAIGN__ADMIN = 'CAMPAIGN-ADMIN',
  CAMPAIGN__LISTVIEW = 'CAMPAIGN-LISTVIEW',
  CAMPAIGN__UPDATE = 'CAMPAIGN-UPDATE',
  CAMPAIGN__DELETE = 'CAMPAIGN-DELETE',
  CAMPAIGN_STEP = 'CAMPAIGN_STEP',
  CAMPAIGN_STEP__CREATE = 'CAMPAIGN_STEP-CREATE',
  CAMPAIGN_STEP__READ = 'CAMPAIGN_STEP-READ',
  CAMPAIGN_STEP__ADMIN = 'CAMPAIGN_STEP-ADMIN',
  CAMPAIGN_STEP__LISTVIEW = 'CAMPAIGN_STEP-LISTVIEW',
  CAMPAIGN_STEP__UPDATE = 'CAMPAIGN_STEP-UPDATE',
  CAMPAIGN_STEP__DELETE = 'CAMPAIGN_STEP-DELETE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  UNSUBSCRIBE__CREATE = 'UNSUBSCRIBE-CREATE',
  UNSUBSCRIBE__READ = 'UNSUBSCRIBE-READ',
  UNSUBSCRIBE__ADMIN = 'UNSUBSCRIBE-ADMIN',
  UNSUBSCRIBE__LISTVIEW = 'UNSUBSCRIBE-LISTVIEW',
  UNSUBSCRIBE__UPDATE = 'UNSUBSCRIBE-UPDATE',
  UNSUBSCRIBE__DELETE = 'UNSUBSCRIBE-DELETE',
  ALLOWED_ACTION = 'ALLOWED_ACTION',
  ALLOWED_ACTION__CREATE = 'ALLOWED_ACTION-CREATE',
  ALLOWED_ACTION__READ = 'ALLOWED_ACTION-READ',
  ALLOWED_ACTION__ADMIN = 'ALLOWED_ACTION-ADMIN',
  ALLOWED_ACTION__LISTVIEW = 'ALLOWED_ACTION-LISTVIEW',
  ALLOWED_ACTION__UPDATE = 'ALLOWED_ACTION-UPDATE',
  ALLOWED_ACTION__DELETE = 'ALLOWED_ACTION-DELETE',
  DOMAIN = 'DOMAIN',
  DOMAIN__CREATE = 'DOMAIN-CREATE',
  DOMAIN__READ = 'DOMAIN-READ',
  DOMAIN__ADMIN = 'DOMAIN-ADMIN',
  DOMAIN__LISTVIEW = 'DOMAIN-LISTVIEW',
  DOMAIN__UPDATE = 'DOMAIN-UPDATE',
  DOMAIN__DELETE = 'DOMAIN-DELETE',
  ROLE = 'ROLE',
  ROLE__CREATE = 'ROLE-CREATE',
  ROLE__READ = 'ROLE-READ',
  ROLE__ADMIN = 'ROLE-ADMIN',
  ROLE__LISTVIEW = 'ROLE-LISTVIEW',
  ROLE__UPDATE = 'ROLE-UPDATE',
  ROLE__DELETE = 'ROLE-DELETE',
  GRANTED_ROLE = 'GRANTED_ROLE',
  GRANTED_ROLE__CREATE = 'GRANTED_ROLE-CREATE',
  GRANTED_ROLE__READ = 'GRANTED_ROLE-READ',
  GRANTED_ROLE__ADMIN = 'GRANTED_ROLE-ADMIN',
  GRANTED_ROLE__LISTVIEW = 'GRANTED_ROLE-LISTVIEW',
  GRANTED_ROLE__UPDATE = 'GRANTED_ROLE-UPDATE',
  GRANTED_ROLE__DELETE = 'GRANTED_ROLE-DELETE',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION__CREATE = 'ORGANIZATION-CREATE',
  ORGANIZATION__READ = 'ORGANIZATION-READ',
  ORGANIZATION__ADMIN = 'ORGANIZATION-ADMIN',
  ORGANIZATION__LISTVIEW = 'ORGANIZATION-LISTVIEW',
  ORGANIZATION__UPDATE = 'ORGANIZATION-UPDATE',
  ORGANIZATION__DELETE = 'ORGANIZATION-DELETE',
  USER = 'USER',
  USER__CREATE = 'USER-CREATE',
  USER__READ = 'USER-READ',
  USER__ADMIN = 'USER-ADMIN',
  USER__LISTVIEW = 'USER-LISTVIEW',
  USER__UPDATE = 'USER-UPDATE',
  USER__DELETE = 'USER-DELETE',
  INSIGHT = 'INSIGHT',
  INSIGHT__CREATE = 'INSIGHT-CREATE',
  INSIGHT__READ = 'INSIGHT-READ',
  INSIGHT__ADMIN = 'INSIGHT-ADMIN',
  INSIGHT__LISTVIEW = 'INSIGHT-LISTVIEW',
  INSIGHT__UPDATE = 'INSIGHT-UPDATE',
  INSIGHT__DELETE = 'INSIGHT-DELETE',
  BLACKLIST = 'BLACKLIST',
  BLACKLIST__CREATE = 'BLACKLIST-CREATE',
  BLACKLIST__READ = 'BLACKLIST-READ',
  BLACKLIST__ADMIN = 'BLACKLIST-ADMIN',
  BLACKLIST__LISTVIEW = 'BLACKLIST-LISTVIEW',
  BLACKLIST__UPDATE = 'BLACKLIST-UPDATE',
  BLACKLIST__DELETE = 'BLACKLIST-DELETE',
}
