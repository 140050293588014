import RouteParams from './RouteParams.interface';

export default interface Route {

  readonly name: RouteName;
  readonly params: RouteParams;

}

export enum RouteName {
  Logon = 'Logon',
  Logoff = 'Logoff',
  ForgotPassword = 'ForgotPassword',
  PasswordExpired = 'PasswordExpired',
  ClientDashboard = 'ClientDashboard',
  Memo = 'Memo',
  Alert = 'Alert',
  AlertInfo = 'AlertInfo',
  AlertSuccess = 'AlertSuccess',
  AlertWarning = 'AlertWarning',
  AlertError = 'AlertError',
  AlertFatal = 'AlertFatal',

  Datasets = 'Datasets',
  DatasetUpload = 'DatasetUpload',

  Clients = 'Clients',
  EditClient = 'EditClient',
  AddClient = 'AddClient',

  CampaignTakeOff = 'CampaignTakeOff',
  CampaignList = 'CampaignList',
  CampaignUnfinishedList = 'CampaignUnfinishedList',
  CampaignConfiguration = 'CampaignConfiguration',
  ContentWizard = 'ContentWizard',
  CampaignDashboard = 'CampaignDashboard',
  StepList = 'StepList',
  StepDashboard = 'StepDashboard',
  // TestContent = 'TestContent',
  ManageTargets = 'ManageTargets',
  ManageClientDNC = 'ManageClientDNC',
  ManageCampaignDNC = 'ManageCampaignDNC',
  ClientLeadList = 'ClientLeadList',
  CampaignLeadList = 'CampaignLeadList',
  LeadDetail = 'LeadDetail',
  Dashboard = 'Dashboard',
  ClientReport = 'ClientReport',
  CampaignReport = 'CampaignReport',

  Config = 'Config',
  ClientUsers = 'ClientUsers',
  Profiles = 'Profiles',
  EditClientUser = 'EditClientUser',
  EditProfile = 'EditProfile',
  AddClientUser = 'AddClientUser',
  AddProfile = 'AddProfile',
  Roles = 'Roles',
  AddRole = 'AddRoles',
  EditRole = 'EditRoles',

  Timeline = 'Timeline',


  // deprecated
  Todo = 'Todo',

  // internal
  Docker = 'Docker',
  Odin403 = 'Odin403',
  Odin404 = 'Odin404',
  GenericOdin404 = 'GenericOdin404',
  Error403 = 'Error403',
  Error404 = 'Error404',
  RedirectToAuthenticate = 'RedirectToAuthenticate',
  Default404 = 'Default404',

  // external
  External = 'External',
  Unsubscribe = 'Unsubscribe',

}
