export enum Operator {
  EQUAL,
  NOT_EQUAL,
  EQUAL_IGNORE_CASE,
  EMPTY,
  NOT_EMPTY,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN,
  LESS_THAN_OR_EQUAL,
  LENGTH_GREATER_THAN,
  LENGTH_GREATER_THAN_OR_EQUAL,
  LENGTH_LESS_THAN,
  LENGTH_LESS_THAN_OR_EQUAL,
}

import Condition from './Condition.interface';

export default interface ValueOf {

  isBoolean(): Boolean;
  isNumber(): Boolean;
  isNumberAnd(operator: Operator, value?: any): Condition;
  isNumberOrString(): Boolean;
  isNumberOrStringAnd(operator: Operator, value?: any): Condition;
  isString(): Boolean;
  isStringAnd(operator: Operator, value?: any): Condition;
  isArray(): Boolean;
  isArrayAnd(operator: Operator, value?: any): Condition;
  isUuid(): Boolean;

}
