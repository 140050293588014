import PasswordI from './Password.interface';

export default class Password implements PasswordI {

  public readonly confirm: boolean;
  public u = '';
  public p = '';
  public compare = '';

  constructor(confirm?: boolean) {
    this.confirm = confirm === true;
  }

  isValidEmail(): boolean {
    return this.u.length < 3 ||
      !this.u.includes('@')
      ? false
      : true;
    return false;
  }

  passwordsMatch(): boolean {
    if (!this.confirm) return true;
    return this.isValidEmail() &&
      this.p.length > 0 &&
      this.compare === this.p;
  }

}
