import HttpMessageI     from './HttpMessage.interface';
import PropertyError  from './PropertyError.class';
import { valueOf }    from '@/utils/checks/value';
import { Operator }   from '@/utils/checks/value/ValueOf.interface';

export default class HttpMessage implements HttpMessageI {

  public readonly status: String;
  public readonly title: String;
  public readonly detail: String;

  public readonly verb: String;
  public readonly url: String;
  public readonly payload: String;

  public readonly propertyErrors: Array<PropertyError>;

  constructor(error: any) {
    // set defaults
    this.title = error ? '' + error.title : 'no title';
    this.detail = error ? '' + error.detail : 'no detail';
    this.status = error ? '' + error.status : 'unknown status';
    this.verb = 'no verb';
    this.url = 'no url';
    this.payload = 'no payload';

    this.propertyErrors = new Array<PropertyError>();
    //
    if (error !== undefined) {
      const config = error.config || {};
      this.verb = '' +  config.method;
      this.url = '' +  config.baseURL + config.url;
      this.payload = typeof config.data === 'string' ? config.data : JSON.stringify(config.data);
      //
      const response = error.response || {};
      this.status = '' + response.status;
      const data = response.data || {};
      this.title = '' + data.title;
      this.detail = '' + data.detail;
      // propertyErrors
      if (valueOf(data.propertyErrors).isArrayAnd(Operator.NOT_EMPTY).check())  {
        this.propertyErrors = data.propertyErrors;
      }
    }
  }

}
