import HasIdI from './HasId.interface';

export default class HasId implements HasIdI {

  public readonly id?: Number | String;

  constructor(id?: Number | String) {
    this.id = id;
  }

}
