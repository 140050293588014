import RouteParamsI from './RouteParams.interface';

export default class RouteParams implements RouteParamsI {

  public client: String;
  public user: String;
  public campaign: String;
  public importset: String;
  public step: String;

  constructor(o: any) {
    const p = o === undefined ? {} : o;
    this.client = '' + p.client;
    this.user = '' + p.user;
    this.campaign = '' + p.campaign;
    this.importset = '' + p.importset;
    this.step = '' + p.step;
  }

  toAny(): any {
    return (this as any);
  }

}
