

import InputPassword        from '@/views/security/InputPassword.vue';
import Password             from '@/views/security/Password.class';
import StorageService       from '@/services/storage/StorageService.class';
import { login }            from '@/http/login/login';
import { RouteName }        from '@/router/Route.interface';
import { StoreKey }         from '@/services/storage/StorageService.interface';

import Vue from 'vue';
export default Vue.extend({
  name: 'Logon',
  components: {
    InputPassword,
  },

  props: {
    resetPasswordInterface: {
      type: Boolean,
      required: false,
      default() { return false; }
    },
  },

  data() {
    return {
      model: new Password(false),
      loginError: '',
      storage: new StorageService(),
    };
  },

  mounted() {
    this.storage.remove(StoreKey.LRES);
    this.$routerLres.clear();
    const myLogin = this.storage.get<string>(StoreKey.USER);
    if (myLogin !== undefined) {
      this.model.u = myLogin;
    }
  },

  methods: {

    buttonClick(): void {
      this.accessOdin();
    },

    accessOdin(): void {
      this.loginError = '';
      login(this.model.u, this.model.p)
        .then((response: any) => { this.evaluateResponse(response); })
        .catch((error: any) => {
          this.loginError = error && error.response ? '' + error.response.data : 'Unknown user of password mismatch...'
        })
        .finally(() => {
          // this.option.isLoading = false;
        });
    },

    evaluateResponse(o?: any) {
      this.storage.store(StoreKey.LRES, '' + JSON.stringify(o));
      const response: any = o === undefined ? {} : o;
      if (response.resetPassword) {
        this.gotoPasswordExpired(response.person.id);
      } else {
        this.$nextTick(() => { this.gotoDashboard(); }); // wait for DOM to build
      }
    },

    gotoPasswordExpired(userId: string): void {
      this.$router.push({ name: RouteName.PasswordExpired, params: { user: userId } });
    },
    gotoDashboard(): void {
      this.$router.push({ name: RouteName.ClientDashboard });
    },

    updateLogonUi() {
      this.$forceUpdate();
    },
    //
  },
});

