
import Vue        from 'vue';
import axios      from 'axios';
import VueAxios   from 'vue-axios';
Vue.use(VueAxios, axios);
import { getUrl } from '../webpackUtil';

const axiosLogInService = createAxiosService();

/**
 * @private
 * make sure that interceptors from request.ts are no longer present
 */
function createAxiosService() {
  return Vue.axios.create({
    baseURL: getUrl(),
    timeout: 120000,
    withCredentials: true,
    headers: {
      Pragma: 'no-cache', // HTTP 1.0
      Expires: 0,         // Proxies
      'Cache-Control': [ 'no-cache', 'no-store', 'must-revalidate' ], // HTTP 1.1
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Credentials': true,
      // 'Access-Control-Allow-Methods': ['GET','HEAD','OPTIONS','POST','PUT'],
    },
    // paramsSerializer: (params) => {
    //   return queryString.stringify(params, {
    //     arrayFormat: 'repeat',
    //     skipNulls: true,
    //   });
    // },
  });
}

/**
 * @private
 * make sure that interceptors from request.ts are no longer present
 */
function resetInterceptors() {
  axiosLogInService.interceptors.request.clear();
  axiosLogInService.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.log(error); // for debug
      Promise.reject(error);
    },
  );

  axiosLogInService.interceptors.response.clear();
  axiosLogInService.interceptors.response.use(
    (response) => {
      const res = response.data;
      if (typeof res === 'string' && res.startsWith('<')) {
        // not logged in, answer was a redirect
        // redirect to login page by throwing error
        return Promise.reject(res);
      } else if (response.status < 200 || response.status > 299) {
        console.error('Error: ' + res.message);
        return Promise.reject(response);
      } else {
        return res;
      }
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

export function login(user: string, password: string): any {
  resetInterceptors();
  return axiosLogInService.post('/login', { email: user, password: password });
};

// result always returned in the rejected promise
export function testUrl(url: String): any {
  const service = createAxiosService();
  service.interceptors.response.use(
    (response) => {
      return Promise.reject({ status: '' + response.status, url: '' + response.config.baseURL + response.config.url });
    },
    (error) => {
      return Promise.reject({ status: '400', url: '' });
    },
  );
  return service.get('' + url);
};

export function logoff(): any {
  resetInterceptors();
  return axiosLogInService.post('api/users/me/logout');
};

export function passwordReset(email : string) {
  resetInterceptors();
  return axiosLogInService.post(
    '/password/reset',
    '' + btoa('' + email),
    {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
};

export function newPasswordByToken(email: string, password: string, token: string) {
  resetInterceptors();
  return axiosLogInService.post(
    '/password/set',
    {
      email: email,
      password: password,
      token: token,
    });
};

export function getEnvironment(email?: String): any {
  resetInterceptors();
  const e = email !== undefined && email !== '' ? email : 'odin';
  return axiosLogInService({
    url: '/environment/' + e,
    method: 'get',
    spinnerVisible: false,
    progressVisible: false,
  });
};
