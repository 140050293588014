import Vue              from 'vue';
import LresContainer    from '@/var/LresContainer.class';
import Lres             from '@/dto/security/Lres.interface';
import RouteVerifyer    from '@/router/RouteVerifyer.class';
import Logon            from '../Logon.vue';
import Unsubscribe      from '@/views/external/Unsubscribe.vue';
import VueRouter,
  {
    RouteConfig,
    RouteRecord,
    Route,
  } from 'vue-router';
import { RouteName }    from '@/router/Route.interface';
import { MessageType }  from '@/services/msg/MessageService.interface';

Vue.use(VueRouter);
Vue.prototype.$routerLres = new LresContainer();
Vue.prototype.$routeVerifyer = new RouteVerifyer();

const routes: Array<RouteConfig> = [

  {
    // password protected
    path: '/odin',
    name: RouteName.Docker,
    component: () => import('@/views/_dock/Docker.vue'),
    meta: { authenticate: true, verifyRoute: true, },
    children: [
      {
        path: 'dashboard',
        name: RouteName.ClientDashboard,
        component: () => import('@/views/home/ClientDashboard.vue'),
        meta: {
          title: undefined,
          crudButtons: false,
          countBar: false,
          verifyRoute: false,
        },
      },
      /*
      {
        path: 'todo',
        name: RouteName.Todo',
        component: () => import('@/views/todo/Todo.vue'),
        // meta: { title: 'home.reminders' },
        // hidden: true,
      },
      */
      {
        path: 'datasets',
        name: RouteName.Datasets,
        component: () => import('@/views/datasets/DatasetList.vue'),
        meta: {
          title: 'Datasets',
          crudButtons: false,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'datasets/upload',
        name: RouteName.DatasetUpload,
        component: () => import('@/views/datasets/DatasetUpload.vue'),
        meta: {
          title: 'Upload Dataset',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },
      /*
       * CLIENTS
       */
      {
        path: 'clients',
        name: RouteName.Clients,
        component: () => import('@/views/clients/ClientList.vue'),
        meta: {
          title: 'Clients',
          crudButtons: true,
          countBar: true,
          verifyRoute: false,
        },
      },
      {
        path: 'clients/:client',
        name: RouteName.EditClient,
        component: () => import('@/views/clients/ClientDetail.vue'),
        meta: {
          title: 'Client',
          crudButtons: true,
          countBar: false,
        },
      },
      {
        path: 'clients/add/new',
        name: RouteName.AddClient,
        component: () => import('@/views/clients/ClientDetail.vue'),
        meta: {
          title: 'Add Client',
          crudButtons: true,
          countBar: false,
        },
      },
      {
        path: 'clients/:client/users',
        name: RouteName.ClientUsers,
        component: () => import('@/views/config/profiles/UserList.vue'),
        meta: {
          title: 'Users',
          crudButtons: true,
          countBar: true,
          verifyRoute: true,
        },
      },
      {
        path: 'clients/:client/users/:user',
        name: RouteName.EditClientUser,
        component: () => import('@/views/config/profiles/ClientUserDetail.vue'),
        meta: {
          title: 'User',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'clients/:client/users/add/new',
        name: RouteName.AddClientUser,
        component: () => import('@/views/config/profiles/ClientUserDetail.vue'),
        meta: {
          title: 'Add User',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      /*
       * CAMPAIGNS
       */
      {
        path: 'campaigns/running',
        name: RouteName.CampaignList,
        component: () => import('@/views/campaigns/CampaignList.vue'),
        meta: {
          title: 'Campaigns',
          crudButtons: false,
          countBar: true,
          verifyRoute: false,
        },
      },
      {
        path: 'campaigns/unfinished',
        name: RouteName.CampaignUnfinishedList,
        component: () => import('@/views/campaigns/CampaignUnfinishedList.vue'),
        meta: {
          title: 'Unfinished Campaigns',
          crudButtons: false,
          countBar: true,
          verifyRoute: false,
        },
      },
      {
        path: 'campaigns/takeoff',
        name: RouteName.CampaignTakeOff,
        component: () => import('@/views/campaigns/TakeOff.vue'),
        meta: {
          title: 'Campaign Take-Off',
          crudButtons: false,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'campaigns/manage/dnc',
        name: RouteName.ManageClientDNC,
        component: () => import('@/views/todo/Todo.vue'),
        meta: {
          title: 'Client DNC Manager',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/leads',
        name: RouteName.ClientLeadList,
        component: () => import('@/views/campaigns/leads/LeadList.vue'),
        meta: {
          title: 'Leads',
          crudButtons: true,
          countBar: true,
          verifyRoute: false,
        },
      },
      {
        path: 'campaigns/report',
        name: RouteName.ClientReport,
        component: () => import('@/views/campaigns/reports/ClientReport.vue'),
        meta: {
          title: 'Report Overview',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/dashboard',
        name: RouteName.CampaignDashboard,
        component: () => import('@/views/campaigns/CampaignDashboard.vue'),
        meta: {
          title: 'Campaign Dashboard',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/configuration',
        name: RouteName.CampaignConfiguration,
        component: () => import('@/views/campaigns/CampaignConfiguration.vue'),
        meta: {
          title: 'Campaign Configuration',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/stepwizard',
        name: RouteName.ContentWizard,
        component: () => import('@/views/campaigns/steps/StepWizard.vue'),
        meta: {
          title: 'Campaign Content Wizard',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/steps',
        name: RouteName.StepList,
        component: () => import('@/views/campaigns/steps/StepList.vue'),
        meta: {
          title: 'Campaign Steps',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/steps/:step/dashboard',
        name: RouteName.StepDashboard,
        component: () => import('@/views/campaigns/steps/StepDashboard.vue'),
        meta: {
          title: 'Campaign Step Dashboard',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/manage/dnc',
        name: RouteName.ManageCampaignDNC,
        component: () => import('@/views/campaigns/dnc/DncUpload.vue'),
        meta: {
          title: 'DNC Upload Manager',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/manage/targets',
        name: RouteName.ManageTargets,
        component: () => import('@/views/campaigns/targetMarket/TargetMarketForm.vue'),
        meta: {
          title: 'Target Market Manager',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/leads',
        name: RouteName.CampaignLeadList,
        component: () => import('@/views/campaigns/leads/LeadList.vue'),
        meta: {
          title: 'Campaign Leads',
          crudButtons: true,
          countBar: true,
          verifyRoute: false,
        },
      },
      {
        path: 'campaigns/:campaign/leads/:conversation',
        name: RouteName.LeadDetail,
        component: () => import('@/views/campaigns/leads/LeadDetail.vue'),
        meta: {
          title: 'Lead',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'campaigns/:campaign/report',
        name: RouteName.CampaignReport,
        component: () => import('@/views/campaigns/reports/CampaignReport.vue'),
        meta: {
          title: 'Report Overview',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },

      /*
       * ALERTS
       */
      {
        path: 'alerts',
        name: RouteName.Alert,
        component: () => import('@/views/alerts/Alerts.vue'),
        meta: {
          title: 'Alerts',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'alerts/info',
        name: RouteName.AlertInfo,
        component: () => import('@/views/alerts/Alerts.vue'),
        meta: {
          title: 'Alerts (info)',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'alerts/success',
        name: RouteName.AlertSuccess,
        component: () => import('@/views/alerts/Alerts.vue'),
        meta: {
          title: 'Alerts (success)',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'alerts/warning',
        name: RouteName.AlertWarning,
        component: () => import('@/views/alerts/Alerts.vue'),
        meta: {
          title: 'Alerts (warning)',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'alerts/error',
        name: RouteName.AlertError,
        component: () => import('@/views/alerts/Alerts.vue'),
        meta: {
          title: 'Alerts (error)',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },
      {
        path: 'alerts/fatal',
        name: RouteName.AlertFatal,
        component: () => import('@/views/alerts/Alerts.vue'),
        meta: {
          title: 'Alerts (fatal)',
          crudButtons: true,
          countBar: false,
          verifyRoute: false,
        },
      },

      /*
       * CONFIG / SETTINGS
       */
      {
        path: 'settings/profiles',
        name: RouteName.Profiles,
        component: () => import('@/views/config/profiles/UserList.vue'),
        meta: {
          title: 'Profiles',
          crudButtons: true,
          countBar: true,
          verifyRoute: true,
        },
      },
      {
        path: 'settings/profiles/:user',
        name: RouteName.EditProfile,
        component: () => import('@/views/config/profiles/ClientUserDetail.vue'),
        meta: {
          title: 'Profile',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'settings/profiles/add/new',
        name: RouteName.AddProfile,
        component: () => import('@/views/config/profiles/ClientUserDetail.vue'),
        meta: {
          title: 'Add Profile',
          crudButtons: true,
          countBar: false,
          verifyRoute: true,
        },
      },
      {
        path: 'settings/roles',
        name: RouteName.Roles,
        component: () => import('@/views/config/roles/RoleList.vue'),
        meta: {
          title: 'Roles',
          crudButtons: true,
          countBar: true,
          verifyRoute: false,
        },
      },
      /*
      {
        path: 'settings/roles/:role',
        name: RouteName.EditRole,
        component: () => import('@/views/config/roles/RoleDetail.vue'),
        meta: {
          hasAccessToAll: [ Privileges.ROLE__READ ],
          title: 'Role',
          crudButtons: true,
          countBar: true,
          verifyRoute: true,
        },
      },
      {
        path: 'settings/roles/add/new',
        name: RouteName.AddRole,
        component: () => import('@/views/config/roles/RoleDetail.vue'),
        meta: {
          hasAccessToAll: [ Privileges.ROLE__CREATE ],
          title: 'Add role',
          crudButtons: true,
          countBar: true,
          verifyRoute: true,
        },
      },
      */

      /*
       * GENERIC
       */
      {
        path: 'logoff',
        name: RouteName.Logoff,
        component: () => import('@/views/home/Logoff.vue'),
        meta: { crudButtons: false, verifyRoute: false, },
      },
      {
        path: '403',
        name: RouteName.Odin403,
        component: () => import('@/views/home/Error403.vue'),
        meta: { crudButtons: false, verifyRoute: false, },
      },
      {
        path: '404',
        name: RouteName.Odin404,
        component: () => import('@/views/home/Error404.vue'),
        meta: { crudButtons: false, verifyRoute: false, },
      },
      {
        path: '*',
        name: RouteName.GenericOdin404,
        component: () => import('@/views/home/Error404.vue'),
        meta: { crudButtons: false, verifyRoute: false, },
      },

    ],
  },

  // without prior authentication check
  {
    path: '/authenticate',
    name: RouteName.Logon,
    component: Logon,
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '/campaign/unsubscribe',
    name: RouteName.Unsubscribe,
    component: Unsubscribe,
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '/resetpassword',
    name: RouteName.ForgotPassword,
    component: () => import('@/views/security/ForgotPassword.vue'),
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '/passwordexpired',
    name: RouteName.PasswordExpired,
    component: () => import('@/views/security/PasswordExpired.vue'),
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '/403',
    name: RouteName.Error403,
    component: () => import('@/views/home/Error403.vue'),
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '/404',
    name: RouteName.Error404,
    component: () => import('@/views/home/Error404.vue'),
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '/*',
    name: RouteName.RedirectToAuthenticate,
    meta: { crudButtons: false, verifyRoute: false, },
    redirect: '/authenticate',
  },
  {
    path: '*',
    name: RouteName.Default404,
    component: () => import('@/views/home/Error404.vue'),
    meta: { crudButtons: false, verifyRoute: false, },
  },
  {
    path: '*',
    name: RouteName.Default404,
    component: () => import('@/views/home/Error404.vue'),
    meta: { crudButtons: false, verifyRoute: false, },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (hasUnsavedData(from) === true)
  {
    next(false);
    return; // stay on page
  }
  setPathVerification(requiresPathVerification(to.matched));
  const routeVerifyer = Vue.prototype.$routeVerifyer;
  const lres = Vue.prototype.$routerLres.get();

  if (requiresPrivileges(routeVerifyer, lres, to.matched)) {
    if (routeVerifyer.principalHasRoutePrivileges(lres, to.name) === true) {
      next();
    } else {
      next({ name: RouteName.Error403, replace: false });
    }
  } else {
    next();
  }
});

export default router;

function requiresPathVerification(matched: Array<RouteRecord>): Boolean {
  if (matched.length === 0) return false;
  let idx = 0;
  for (idx = 0; idx < matched.length; idx++) {
    const route = matched[idx];
    if (route.meta && route.meta.verifyRoute === false) return false;
  }
  for (idx = 0; idx < matched.length; idx++) {
    const route = matched[idx];
    if (route.meta && route.meta.verifyRoute === true) return true;
  }
  return false;
}

function requiresPrivileges(routeVerifyer: RouteVerifyer, lres: Lres, matched: Array<RouteRecord>): Boolean {
  if (matched.length === 0) return false;
  let idx = 0;
  for (idx = 0; idx < matched.length; idx++) {
    const route = matched[idx];
    if (routeVerifyer.requiresPrivileges(lres, '' + route.name) === true) return true;
  }
  return false;
}

function setPathVerification(bool: Boolean): void {
  if (bool === true) {
    Vue.prototype.$routeVerifyer.start();
  } else {
    Vue.prototype.$routeVerifyer.setValid();
  }
}

function logRouterWarning(route: Route, message: string): void {
  console.warn('ROUTER warning: <' + route.name +'> ' + message);
}

function hasUnsavedData(from: Route): Boolean {
  if (from.path === '/') return false;
  const meta: any = from.meta || {};
  if (meta.crudButtons === undefined) {
    logRouterWarning(from, 'is missing the meta.crudButtons configuration');
  }
  if (meta.crudButtons !== true) return false;
  const routeHasUnsavedData = saveButtonIsEnabled(from);
  if (routeHasUnsavedData) addMessageUserMustSave('' + meta.title);
  return routeHasUnsavedData;
}

function saveButtonIsEnabled(route: Route): Boolean {
  const button = Vue.prototype.$global.input.buttonGroupCrud.getButton('crud$save');
  const isEnabled = !button.isDisabled();
  if (isEnabled) button.blink();
  return isEnabled;
}

function addMessageUserMustSave(routeTitle: string): void {
  const msgService = Vue.prototype.$global.msg;
  msgService.addMessage(MessageType.WARNING, routeTitle + ': save or cancel current operation');
}
