import RouteVerifyerI,
  {
    VerificationStatus,
    RouteAccessBuilderI,
  } from './RouteVerifyer.interface';
import RouteAccessBuilder from './RouteAccessBuilder.class';
import RouteParams        from './RouteParams.interface';
import UpdateEmitter      from '@/var/UpdateEmitter.class';
import Lres               from '@/dto/security/Lres.interface';
import { Privileges }     from '@/dto/security/Privileges';
import { RouteName }      from '@/router/Route.interface';
import { Scope }          from '@/var/UpdateEmitter.interface';

export default class RouteVerifyer extends UpdateEmitter implements RouteVerifyer {

  private status: VerificationStatus = VerificationStatus.VERIFYING;
  private timeoutID?: NodeJS.Timeout = undefined;

  constructor() {
    super('RouteVerifyer');
  }

  getStatus(): VerificationStatus {
    return this.status;
  }

  private getUrlParts(vueRoute: any): Array<String> {
    const route = typeof vueRoute === 'object' ? vueRoute : {};
    const path = '' + route.path;
    return path.split('/');
  }

  getUrlLastPart(vueRoute: any): String {
    const parts = this.getUrlParts(vueRoute);
    const idx = parts.length - 1;
    return idx >= 0 ? '' + parts[idx] : 'undefined';
  }

  getUrlSecondLastPart(vueRoute: any): String {
    const parts = this.getUrlParts(vueRoute);
    const idx = parts.length - 2;
    return idx >= 0 ? '' + parts[idx] : 'undefined';
  }

  start(): void {
    this.status = VerificationStatus.VERIFYING;
    this.emit(Scope.ROUTE_VERIFICATION, this.status);
    this.timeoutID = setTimeout(() => { this.autoReload(); }, 3000);
  }
  setValid(): void {
    this.status = VerificationStatus.VALID;
    this.clear();
  }
  setInvalid(error: any): void {
    const e: any = error === undefined ? {} : error;
    if (e.status === '403') this.status = VerificationStatus.UNAUTHORIZED;
    else this.status = VerificationStatus.INVALID_PATH;
    this.clear();
  }
  setInvalidPath(): void {
    this.status = VerificationStatus.INVALID_PATH;
    this.clear();
  }
  setUnauthorized(): void {
    this.status = VerificationStatus.UNAUTHORIZED;
    this.clear();
  }

  private clear(): void {
    this.emit(Scope.ROUTE_VERIFICATION, this.status);
    if (this.timeoutID !== undefined) clearTimeout(this.timeoutID);
    this.timeoutID = undefined;
  }

  private autoReload(): void {
    window.location.reload();
  }

  requiresPrivileges(lres: Lres, routeName: RouteName | String): Boolean {
    return this.getRequiredPrivileges(lres, routeName).length !== 0;
  }

  getRequiredPrivileges(lres: Lres, routeName: RouteName | String): Array<Privileges> {
    const notPo = !lres.isPlatformOwner();
    const required = new Array<Privileges>();
    switch (routeName) {
      // no privileges required
      case RouteName.Logon:
      case RouteName.ForgotPassword:
      case RouteName.PasswordExpired:
      case RouteName.Alert:
      case RouteName.AlertInfo:
      case RouteName.AlertSuccess:
      case RouteName.AlertWarning:
      case RouteName.AlertError:
      case RouteName.AlertFatal:
      case RouteName.CampaignTakeOff: // handle insufficient rights in view
      case RouteName.ClientDashboard:   // handle insufficient rights in view
      case RouteName.Logoff: break;

      // campaign related
      case RouteName.CampaignList:
      case RouteName.CampaignUnfinishedList:
        required.push(Privileges.CAMPAIGN__LISTVIEW);
        break;
      case RouteName.CampaignConfiguration:
      case RouteName.ContentWizard:
      case RouteName.LeadDetail:
      case RouteName.CampaignDashboard:
      case RouteName.ManageCampaignDNC:
      case RouteName.ManageClientDNC:
      case RouteName.ManageTargets:
      case RouteName.ClientLeadList:
      case RouteName.CampaignLeadList:
        required.push(Privileges.CAMPAIGN__READ);
        break;
      case RouteName.StepList:
        required.push(Privileges.CAMPAIGN__READ);
        required.push(Privileges.CAMPAIGN_STEP__LISTVIEW);
        break;
      case RouteName.StepDashboard:
        required.push(Privileges.CAMPAIGN__READ);
        required.push(Privileges.CAMPAIGN_STEP__READ);
        break;
      case RouteName.ClientReport:
      case RouteName.CampaignReport:
        required.push(Privileges.CAMPAIGN_REPORT__READ);
        break;

      // user related
      case RouteName.Profiles:
        required.push(Privileges.USER__LISTVIEW);
        break;
      case RouteName.EditProfile:
        required.push(Privileges.PERSON__READ);
        required.push(Privileges.USER__READ);
        break;
      case RouteName.AddProfile:
        required.push(Privileges.PERSON__CREATE);
        required.push(Privileges.USER__CREATE);
        required.push(Privileges.GRANTED_ROLE__CREATE);
        break;

      // platform owner readonly
      case RouteName.Datasets:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.IMPORT_SET__LISTVIEW);
        break;
      case RouteName.DatasetUpload:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.IMPORT_SET__CREATE);
        break;
      case RouteName.Clients:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.CLIENT__LISTVIEW);
        break;
      case RouteName.EditClient:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.CLIENT__READ);
        break;
      case RouteName.AddClient:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.CLIENT__CREATE);
        break;
      case RouteName.ClientUsers:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.USER__LISTVIEW);
        break;
      case RouteName.EditClientUser:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.PERSON__READ);
        required.push(Privileges.USER__READ);
        break;
      case RouteName.AddClientUser:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.PERSON__CREATE);
        required.push(Privileges.USER__CREATE);
        required.push(Privileges.GRANTED_ROLE__CREATE);
        break;
      case RouteName.Config:
        if (notPo) required.push(Privileges.NO_ACCESS);
        break;
      case RouteName.Roles:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.ROLE__LISTVIEW);
        break;
      case RouteName.EditRole:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.ROLE__READ);
        break;
      case RouteName.AddRole:
        if (notPo) required.push(Privileges.NO_ACCESS);
        required.push(Privileges.ROLE__CREATE);
        break;

      // internal: no privileges required
      case RouteName.Docker:
      case RouteName.Odin403:
      case RouteName.Odin404:
      case RouteName.GenericOdin404:
      case RouteName.Error403:
      case RouteName.Error404:
      case RouteName.RedirectToAuthenticate:
      case RouteName.Default404:
      case RouteName.Unsubscribe:
      case RouteName.External: break;

      // not implemented
      case RouteName.Dashboard:
      case RouteName.Timeline:
      case RouteName.Memo:
      default:
        required.push(Privileges.NO_ACCESS);
        break;
    }
    return required;
  }

  principalHasRoutePrivileges(lres: Lres, routeName: RouteName): Boolean {
    const required = this.getRequiredPrivileges(lres, routeName);
    for (let idx = 0; idx < required.length; idx++) {
      const privilege = required[idx];
      if (!lres.getAllowedActions().includes(privilege)) {
        return false;
      }
    }
    return true;
  }

  accessRoute(lres: Lres, routeName: RouteName): RouteAccessBuilderI {
    return new RouteAccessBuilder(routeName, this.principalHasRoutePrivileges(lres, routeName));
  }

}
