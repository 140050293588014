
export function getUrl(suffix?: string): string {
  /* jshint ignore:start */
  const _env = typeof process.env === 'object' ? process.env : { NODE_ENV: 'local' }; // process.env is injected via vue-cli-service build --mode production
  const _mode = _env.NODE_ENV || 'local';
  /* jshint ignore:end */

  const sfx = suffix !== undefined ? '' + suffix : '';
  let result = '';
  switch (_mode) {
    case 'production':
      result = '/';
      break;
    case 'local':
    default:
      result = 'http://localhost:8080/';
      break;
  }
  return result + sfx;
}
