

import {
  UISettings,
  DefaultUISettings }         from './UISettings.class';
import EnvironmentClass       from '@/dto/security/Environment.class';

import type { PropType }  from 'vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'Environment',

  props: {
    environment: {
      type: Object as PropType<EnvironmentClass>,
      required: true,
      default() {
        return new EnvironmentClass();
      },
    },
    width: {
      type: String,
      required: true,
      default() {
        return '200px';
      },
    },
    height: {
      type: String,
      required: true,
      default() {
        return '100px';
      },
    },
    clientLogoUrl: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
  },

  computed: {
    clientLogoStyleProp() {
      if (this.clientLogoUrl && this.clientLogoUrl.length) return 'background-image: url(' + this.clientLogoUrl + ');';
      else return '';
    },
  },

  watch: {
    'environment.uuid': function () {
      this.init();
    },
  },

  activated() {
    this.init();
  },

  data() {
    return {
      defaultColors: new DefaultUISettings(),
    }
  },

  methods: {
    init() {
      this.initCssVariables(this.environment.settings);
    },

    getClass(): String {
      return 'user-environment ' + this.environment.code;
    },

    initCssVariables(settings: UISettings) {
      const rootEl: any = document.querySelector(':root') || {};
      const rootStyle = rootEl.style || {};
      rootStyle.setProperty('--client-accent-color', settings.clientAccent ? '' + settings.clientAccent : this.defaultColors.clientAccent);
    },
    //
  },
});

