
import Vue from 'vue';
import '@/assets/css/scheme-light.scss';
import '@/assets/css/scheme-dark.scss';
import '@/assets/css/scheme-both.scss';

// User preferences
import { Preferences } from '@/components/preferences/Preferences.class';
Vue.prototype.$userPrefs = new Preferences();

export default {};
