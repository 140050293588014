
import EnvironmentClass 		from '@/dto/security/Environment.class';
import Environment 					from '@/components/environment/Environment.vue';
import Vue 									from 'vue';
import { testUrl } 					from '@/http/login/login';
import StorageService 			from '@/services/storage/StorageService.class';
import { StoreKey } 				from '@/services/storage/StorageService.interface';

	
export default Vue.extend({
	components: {
		Environment,
	},

	data() {
		return {
			environment: new EnvironmentClass(),
			storage: new StorageService(),
			emailAddress: '',
			context: '',
			clientLogoUrl: '',
		};
	},

	created() {
		this.init();
	},

	methods: {
		init() {
			this.emailAddress = this.$route.query.email.toString() ?? '';
			this.context = this.$route.query.context.toString() ?? '';
			if (this.context != '') {
				this.testUrlOfClientLogo();
			}
		},

		testUrlOfClientLogo() {
      testUrl('logos/' + this.context)
        .then((response: any) => {})
        .catch((statusAndUrl: any) => {
          this.setClientLogoUrl(statusAndUrl);
        });
    },
    setClientLogoUrl(response: any) {
      if (!response || response.status !== '200') {
        this.clientLogoUrl = '';
        return;
      }
      this.clientLogoUrl = '' + response.url;
      this.storage.store(StoreKey.CLIENT_LOGO_URL, this.clientLogoUrl.toString());
    },
	}
});

