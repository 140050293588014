import Lres, { InitializerI } from './Lres.interface';
import { ValidLres, InvalidLres } from './Lres.class';

export class Initializer implements InitializerI {

  public getLresInstance(o: any): Lres {

    const client = o === undefined ? {} : o.client || {};
    const clientUuid = client.uuid;
    if (clientUuid === undefined) return new InvalidLres();

    const person = o === undefined ? {} : o.person || {};
    const personId = person.id;
    if (personId === undefined) return new InvalidLres();

    const allowedActions = o === undefined ? [] : o.allowedActions || [];
    if (!Array.isArray(allowedActions) || allowedActions.length === 0) return new InvalidLres();

    return new ValidLres(o);
  }
}
