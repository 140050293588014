export enum StoreKey {
  PREFS = 'PREFS',
  USER = 'USER',
  SECURITY = 'SECURITY',
  LRES = 'LRES',
  CLIENT_LOGO_URL = 'CLIENT_LOGO_URL',
  USER_PHOTO_URL = 'USER_PHOTO_URL',
}

import UpdateEmitter, { DetectableElementI } from '@/var/UpdateEmitter.interface';
import {
  ColorScheme,
  NavBarPosition,
  // NavBarAppearance,
  NavBarShape,
  NavBarType,
  Language,
  ViewHeight,
} from '@/components/preferences/Preferences.interface';

export default interface StorageService {

  registerUpdateCallback(_registrationId: string, fn: Function, detectableElement: DetectableElementI): void;

  hasKey(key: StoreKey): Boolean;
  get<T>(key: StoreKey): T | undefined;
  store(key: StoreKey, value: string): void;
  remove(key: StoreKey): void;

}

export interface ServerStorePropsI extends UpdateEmitter {

  colorScheme: ColorScheme;
  navBarShape: NavBarShape;
  navBarPosition: NavBarPosition;
  navBarType: NavBarType;
  language: Language;
  stepsViewHeight: ViewHeight;

  update(o: ServerStorePropsI): void;
  getProperties(): any;
  equals(preferences: ServerStorePropsI): Boolean;

}
